@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

@font-face {
  font-family: 'Bembo Bold';
  src: local('Bembo Bold'), url(../../assets/fonts/Bembo-Bold.woff2) format('woff');
}

@font-face {
  font-family: 'Bembo Regular';
  src: local('Bembo Regular'), url(../../assets/fonts/Bembo-Regular.woff2) format('woff');
}

@font-face {
  font-family: 'Bembo Semibold';
  src: local('Semibold'), url(../../assets/fonts/Bembo-Semibold.woff2) format('woff');
}




html {
  font-size: 62.5%;
  line-height: 1.25;
  body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

p,
span {
  font-size: 1.4rem;
}

a {
  font-weight: 600;
}


.marquee__inner{
  font-family: 'Bembo Regular';
  font-style: italic;
  color: rgb(200, 200, 200);
}
.logo{
  font-family: 'Bembo Regular';
  font-weight: 300;
}

.scroll{
  font-family: 'Bembo Regular';
  font-weight: 300;
}