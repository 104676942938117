$text: #2e293c;
$secondary: #a09da6;

.ml-5{
    margin-left: 22%!important;
    @media (max-width: $layout-breakpoint-small) {
      margin-left: 30%!important;
    }
}

.m-m-t-10{
    margin-top: -5%;
}

.m-m-t-15{
  margin-top: -25%;
}

.width-100{
    width: 100%;
}

.m-xy-s{
    margin: 0px 100px;
}


.text-section{
    
    width: 610px;
    margin-top: -24%;
    @media (max-width: $layout-breakpoint-medium) {
      width: 450px;
      
     
    }
    .title-2{
      font-size: 6rem;
      font-weight: 600;
      margin-bottom: 50px;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 4rem;
        margin-bottom: 35px;
      }
    }
    .para{
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 20px;
      color: #363232;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 1.4rem!important;
        line-height: 2.2rem!important;
      }
    }
  }

  .para{
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
    color: #363232;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 1.4rem!important;
      line-height: 2.2rem!important;
    }
  }

  .mt-10{
    margin-top: 8%;
  }

  .mt-10-bottom{
    margin-top: 70rem;
  }


  .mt-20{
    margin-top: 20%;
  }
  .mt-min-10{
    margin-top: -35%;
  }

  .p-0{
    padding: 0px!important;
  }

  .mt-6{
    margin-top: 5%;
  }

  .color-white{
    color: #fff!important;
  }

  .relative-posision{
    position: relative;
    z-index: -1;
  }

  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000004a;
    top: 0px;
  }


  
.testimonial-section {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  &:after {
    content: "";
    position: absolute;
    width: 900px;
    height: 550px;
    background: #f2f2f6;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    z-index: -10;
  }
  .testimonial-container {
    width: 1480px;
    min-width: 1480px;
    height: 600px;
    position: relative;
    .arrows {
      position: absolute;
      display: flex;
      width: 100px;
      align-items: center;
      justify-content: center;
      bottom: 0;
      top: 0;
      cursor: pointer;
      border-radius: 8px;
      transition: 0.3s ease-in-out;
      &:hover {
        
      }
      &.right {
        right: 0;
      }
    }
    .inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 600px;
      .t-image {
        width: 500px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        ul {
          display: flex;
          position: absolute;
          overflow: hidden;
          height: 460px;
          width: 340px;
          
          li {
            height: 460px;
            width: 340px;
            img {
              height: 460px;
              width: 340px;
            }
          }
        }
      }
      .t-content {
        width: 600px;
        height: 600px;
        display: flex;
        align-items: center;
        ul {
          position: absolute;
          overflow: hidden;
          width: 600px;
          height: 400px;
          li {
            width: 700px;
            height: 400px;
            color: $text;
            display: flex;
            align-items: center;
            position: absolute;
            opacity: 0;
       
           
  
            .content-inner {
              width: 500px;
              .quote {
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.6rem;
                margin-bottom: 20px;
                color: #363232;
              }
              .name {
                font-size: 18px;
                letter-spacing: 0.88px;
                line-height: 28px;
                color: $secondary;
                font-weight: 600;
              }
              .title {
                font-size: 18px;
                letter-spacing: 0.88px;
                line-height: 28px;
                color: $secondary;
              }
            }
          }
        }
      }
    }
  }
}

.arrows img {
    width: 70px;
    height: 70px;
    border: 1px solid #c7c1c1;
    border-radius: 50%;
    padding: 20px;

}

.name .font-bembo{
  font-size: 2.5rem!important;
}

.text-section .f-new-s{
  font-size: 5rem!important;
}


.f-new-s{
  font-size: 5rem!important;
}

.f-new-s6{
  font-size: 6rem!important;
}
.min-55{
  margin-top: -45%;
  width: 530px;
  padding-left: 20px;

}

.min-88{
  margin-top: -78%;
  width: 530px;
  padding-left: 20px;

}

.width-200{
  width: 400px;
  margin: 50px;

  @media (max-width: $layout-breakpoint-medium) {
    width: 295px;
    margin: 30px;
  }

  @media (max-width: $layout-breakpoint-small) {
    width: 250px;
  margin: 20px;
  }
}

.width-500{
  width: 500px;

  @media (max-width: $layout-breakpoint-small) {
    width: 250px;
    margin: 20px;
  }
}

.min-m-top-3{
  margin-top: -8%;
}

.last-line-header .banner-row{
  margin-left: 40%!important;
  @media (max-width: $layout-breakpoint-small) {
    margin-left: 120px!important;
  }
}

.text-section.our-bilef{
  margin-top: -70%;
}

.mobile-m-top{
  margin-top: 20%;
}

.bg-img-section{
  background-size: cover;
    padding-top: 15%;
    padding-bottom: 15%;

   
}


.mt-15{
  margin-top: 15%;
}

.transition-image.mt-final.we-are-viluxr-img{
    margin-top: 0px!important;
}

 .marquee  .padding-redus .row-title {
  padding: 0 0vw;
}

.banner .padding-redus .row-title{
  line-height: 0px!important;
}