.footer{
    margin-top: 6%;
    height: 44vh;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (max-width: $layout-breakpoint-medium) {
        height: 45vh;
        margin-top: 4%;
      }

      @media (max-width: $layout-breakpoint-small) {
        height: auto;
        
      }
}


.footer_link{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 0rem!important;
    @media (max-width: $layout-breakpoint-small) {
        display: block;
        padding-left: 0rem;

      }
}

.footer-text-sec{
    font-size: 4rem;
    margin-top: 28px;
    @media (max-width: $layout-breakpoint-medium) {
        font-size: 2.5rem;
        margin-top: 11px;

      }
    @media (max-width: $layout-breakpoint-small) {
        font-size: 3.5rem!important;
        line-height: 100%!important;
        margin-top: 0px;

      }
     
}

.last-link-footer{
    margin-right: 0px!important;
}

.footer_link li{
    font-size: 4rem;
    font-weight: 600;
    margin-right: 18px;
    line-height: 20px;
    @media (max-width: $layout-breakpoint-medium) {
        font-size: 2.5rem;
        line-height: 18px;
      }
      @media (max-width: $layout-breakpoint-small) {
        font-size: 3.5rem!important;
        line-height: 58px!important;
        margin-right: 0px;
        margin-bottom: 50px!important;
      }
}

.footer .img{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.hover-white:hover{
    cursor: pointer;
}
.hover-white{
    transition: 0.3s;
}
.img-item-1{
    transition: 0.3s;
}
.img{
    transition: 0.3s; 
}
.img img{
    transition: 0.3s; 
}
.footer-img-container{
    transition: 0.3s; 
}

.f_title{
    z-index: 9;
    width: 90%;
    @media (max-width: $layout-breakpoint-medium) {
        width: 90%;
      }
      @media (max-width: $layout-breakpoint-small) {
        margin-left: 0%;
        width: 100%;
        padding: 0px 30px;
      }
}

.footer .img img{
    width: 100%;
    overflow: hidden;

    @media (max-width: $layout-breakpoint-small) {
       display: none;
      }
}

.footer_link li:hover .img{
    opacity: 1;
}

.copy_right{
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px 0px;
    width: 90%;
    margin: 0 auto;

    @media (max-width: $layout-breakpoint-small) {
        font-size: 1.2rem;
        margin-bottom: 80px;
    
       }
}
.footer-svg svg{
    fill: #2d2b2b;
}
.footer-svg-mleft{
    margin-left: 20px;
}
.footer-menu-item{
    margin-left: 50px;
    @media (max-width: $layout-breakpoint-small) {
        font-size: 1.2rem;
        margin-left: 20px;
       }
}

.hiring{
    text-transform: uppercase;
    margin-left: 10px;
    
}
.footer_link li span{
 
    @media (max-width: $layout-breakpoint-small) {
        font-size: 1.3rem;
       }

}
.footer-text-sec{
    display: flex;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif!important;
      font-weight: 600!important;
      color:#24242a!important;
      font-style: normal!important;

}


.footer-svg{
    margin-left: 20px;
}

