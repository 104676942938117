.we-got-you{
  margin-top: 4%;
  width: 500px;
  margin-left: 12%;
  @media (max-width: $layout-breakpoint-medium) {
    margin-top: -20%;
  }
  @media (max-width: $layout-breakpoint-small) {
    margin-top: 45%;
    margin-bottom:24%;
    margin-left: 0%;
    width: 100%;
    padding: 0px 30px;
  }
  .width-para-s{
    width: 620px;
  }


  .title-2{
    font-size: 6rem;
    font-weight: 600;
    margin-bottom: 50px;
    text-transform: uppercase;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 4rem;
      margin-bottom: 35px;
    }
    @media (max-width: $layout-breakpoint-small) {
      margin-bottom: 25px;
      font-size: 3rem;
    }
  }
  .para{
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
    color: #363232;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 1.4rem!important;
      line-height: 2.2rem!important;
    }
  }
}
.relatioive{
  position: relative;
}

.we-got-you.width-800{
  width: 800px!important;
  @media (max-width: $layout-breakpoint-medium) {
    margin-top: 11%;
  }

  @media (max-width: $layout-breakpoint-small) {
    width:auto!important;
    margin-top: 39%;
  }

}

.width-700{
  width: 600px!important;
  @media (max-width: $layout-breakpoint-small) {
    width:auto!important;
  }
}

.width-para-s{
  width: 620px;
  @media (max-width: $layout-breakpoint-small) {
    width:auto!important;
  }
}