.img-100{
    width: 100%;
}
a{
  color: none;
  text-decoration: none;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover{
  color: none!important;
  text-decoration: none!important;
}
.item-2{
    margin-top: 20%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 20%;
    }
}
.neo{
    margin-top: 15%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 20%;
    }
}

.item-2 .img-100{
    margin-left: -20px;
}

.item-2.item-3 .img-100{
    margin-left: 10px!important;
}

.neo .container-fluid{
    margin: 0px;
    padding: 0px;
}

.neo .container-fluid .row{
    margin: 0px;
    padding: 0px;

}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6{
    padding: 0px!important;
}

.mt-version-top{
  @media (max-width: $layout-breakpoint-medium) {
   margin-top: 100px!important;
   
  }
}

.we-got-you-2-mobile{
  margin-top: -30%;
  width: 600px;
  margin-left: 12%;
  @media (max-width: $layout-breakpoint-medium) {
    width: 450px;
    
   
  }
  @media (max-width: $layout-breakpoint-small) {
    
    margin-top: 4%;
    margin-left: 0%;
    width: 100%;
    padding: 0px 30px;
  }
  .title-2{
    font-size: 6rem;
    font-weight: 600;
    margin-bottom: 50px;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 4rem;
      margin-bottom: 35px;
    }
    @media (max-width: $layout-breakpoint-small) {
      font-size: 3rem;
      margin-bottom: 25px;
    }
  }
  .para{
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
    color: #363232;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 1.4rem!important;
      line-height: 2.2rem!important;
    }
  }
}

.text-uppercase{
  text-transform: uppercase!important;
}


.we-got-you-2{
  
    width: 600px;
    margin-left: 12%;
    @media (max-width: $layout-breakpoint-medium) {
    width: 450px;
    }
    @media (max-width: $layout-breakpoint-small) {
      margin-top: 10%;
      
      margin-left: 0%;
      width: 100%;
      padding: 0px 30px;
    }
    .title-2{
      font-size: 6rem;
      font-weight: 600;
      margin-bottom: 50px;
      
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 4rem;
        margin-bottom: 35px;
      }
      @media (max-width: $layout-breakpoint-small) {
        font-size: 3rem;
        margin-bottom: 25px;
      }
    }
    .para{
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 20px;
      color: #363232;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 1.4rem!important;
        line-height: 2.2rem!important;
      }
    }
  }

.we-got-you-3{
    margin-top: -42%;
    width: 610px;
    margin-left: 12%;
    @media (max-width: $layout-breakpoint-medium) {
      width: 450px;
      margin-top: -61%;
       margin-left: 7%;
     
    }
    @media (max-width: $layout-breakpoint-small) {
      margin-top: 45%;
      margin-left: 0%;
      width: 100%;
      padding: 0px 30px;
    }
    .title-2{
      font-size: 6rem;
      font-weight: 600;
      margin-bottom: 50px;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 4rem;
        margin-bottom: 35px;
       
      }
      @media (max-width: $layout-breakpoint-small) {
        font-size: 3rem;
        margin-bottom: 25px;
        
      }
    }
    .para{
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 20px;
      color: #363232;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 1.4rem!important;
        line-height: 2.2rem!important;
      }
    }
  }


  .m-min-top-10{
    margin-top: -22%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: -16%;
     
    }

  }

  
  .m-min-top-20{
    margin-top: 3%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 3%;
     
    }

  }

  .emma-img{
    margin-top: -58%!important;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: -55%!important;
     
    }
  }

  .mt-version-top{
    margin-top: 8%!important;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 13%!important;
     
    }
  }

  .min-top-we-are{
    margin-top: 24%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 26%
     
    }
  }
  .we-got-you-4{
   margin-top: 12%;
    width: 610px;
    margin-left: 12%;
    @media (max-width: $layout-breakpoint-medium) {
      width: 450px;
      margin-top: 12%;
     
    }
    @media (max-width: $layout-breakpoint-small) {
      margin-top: 85%;
      margin-left: 0%;
      width: 100%;
      padding: 0px 30px;
    }
    .title-2{
      font-size: 6rem;
      font-weight: 600;
      margin-bottom: 50px;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 4rem;
        margin-bottom: 35px;
      }

      @media (max-width: $layout-breakpoint-small) {
        font-size: 3rem;
        margin-bottom: 25px;
        
      }
    }
    .para{
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 20px;
      color: #363232;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 1.4rem!important;
        line-height: 2.2rem!important;
      }
    }
  }


  .flip-animate span{
    color: #121212;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 1.4rem!important;
     
    }
  }

  .underline{
    margin-top: 70px;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 50px;
    }
  }

  .underline .flip-animate {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }

  .vision{
    font-size: 7rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.3em;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 4.5rem;
    padding: 0px 0px;
    }

    @media (max-width: $layout-breakpoint-small) {
      font-size: 3rem;
      padding: 40px 30px;
    }
  }

  .mt-a5{
    margin-top: 11%;
  }
  .mt-top-5{
    margin-top: 5%!important;
  }

  .mt-n-5{
    margin-top: 10%!important;
  }

  .font-bembo{
    font-family: "Bembo Regular";
    font-style: italic;
    color: rgb(200, 200, 200);
    font-size: 9rem;
    font-weight: 300!important;
    @media (max-width: $layout-breakpoint-medium) {
      font-size: 5.8rem;
    }

    @media (max-width: $layout-breakpoint-small) {
      font-size: 4rem;
    }


  }

  .img-svg{
    width: 710px;
    position: absolute;
    right: 0px;
    @media (max-width: $layout-breakpoint-medium) {
      width: 475px;
      
    }
    @media (max-width: $layout-breakpoint-small) {
      width: 350px;
      
    }
  }


  .custom-mrgn-top{
    margin-top: -29%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: -29%;
      
    }
  }
  .last-round{
    margin-top: -62%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: -62%;
      
    }
  }

  .the-future-section{
    margin-top: 8%!important;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: 10%!important;
      
    }
  }

  .img-svg-2{
    width: 650px;
    position: absolute;
    left: 0px;
    @media (max-width: $layout-breakpoint-medium) {
      width: 350px;
      
    }
  }

  .image-container{
    position: relative;
    margin-top: -50%;
    @media (max-width: $layout-breakpoint-medium) {
      margin-top: -53%;
      
    }
    @media (max-width: $layout-breakpoint-small) {
      margin-top: -189%;
      
    }
  }

  .mobile-top{
    margin-top: 55%!important;
  }

  .row-top-min .col-12{
    margin-top: -25%;
  }


  .row-top-min-2 .col-12{
    margin-top: -30%;
  }

  .row-top-min-3 .col-12{
    margin-top: -60%;
  }

  .align-center-flex .marquee__inner{
    align-items: center;
  }

  .mobile-row-top{
    
    margin-top: 30%;
  }

  .mobile-top-mrgn{
    margin-top: 35%;
  }

  .mt-flied-sec{
    margin-top: 25%!important;
    margin-bottom: 25%!important;
  }


  .relatioive{
    position: relative;
  }
  
  .title-before:before{
    content: "01";
    font-size: 15px;
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    border: 1px solid #c1bdbd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -51px;
    top: 7px;
    color: #050304;
    padding-top: 1px;
  
  }

  .sec-2:before{
    content: "02"!important; 
  }

  .sec-3:before{
    content: "03"!important; 
  }

  .sec-4:before{
    content: "04"!important; 
  }

  .we-are-viluxur-mt{
    margin-top: -15%;
  }

  .mt-5-new{
    margin-top: 7%;
  }

  .we-are-local-mt{
    margin-top: -11%;
  }

  .we-are-future-mt{
    margin-top: -18%;
  }

  .item-3-new{
    margin-top: 10%;
  }

  .quote-icon-gray{
    position: relative;
  }
  .quote-icon-gray svg{
    fill: #b7babd;
    position: absolute;
    width: 70px;
    height: 70px;
    top: -70px;
    left: -16px;
    @media (max-width: $layout-breakpoint-medium) {
      width: 50px;
      height: 50px;
      top: -46px;
      left: -18px;
  }
      
  @media (max-width: $layout-breakpoint-small) {
    fill: #e8e8e8;
    top: -57px;
    left: -16px
}
    
    

  }

  .quote-icon-gray-2{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quote-icon-gray-2 svg{
    fill: #d9d7d7;
    width: 80px;
    height: 80px;

    @media (max-width: $layout-breakpoint-medium) {
      width: 60px;
    height: 60px;
    margin-bottom: -20px;
    }
    
  }

  .MuiMasonry-root.css-1aa87up-MuiMasonry-root{
    margin: 0px !important;;
  }