.banner {
  z-index: 100;
  @media (max-width: $layout-breakpoint-medium) {
    z-index: 0;
  }
  @media (max-width: $layout-breakpoint-small) {
    z-index: 0;
    margin-top: 70px;
  }

  
  position: relative;
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    @media (max-width: $layout-breakpoint-medium) {
      &:first-child {
        margin-top: 0px;
      }
    }
    @media (max-width: $layout-breakpoint-xsmall) {
      margin-top: 0px;
    }
  }

    &.center {
      justify-content: center;
      text-align: center;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
    }
    .row-title,
    .row-letter {
      font-size: 26rem;
      font-weight: 500;
      position: relative;
      letter-spacing: -0.8rem;
      display: inline-block;
      white-space: nowrap;
      line-height: 1em;
      @media (max-width: $layout-breakpoint-medium) {
        font-size: 15rem;
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 8.5rem;
        letter-spacing: -0.7rem;
      }
    }
    .row-col {
      width: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-start;
      }
      .row-message {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 360px;
        @media (max-width: $layout-breakpoint-medium) {
          font-size: 1.4rem!important;
          line-height: 2.2rem!important;
        }
      }
    }
    .row-col-old {
      width: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      margin-top: 3%;
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
        margin-top: 1%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-start;
      }
      .row-message {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 360px;
        @media (max-width: $layout-breakpoint-medium) {
          font-size: 1.4rem!important;
          line-height: 2.2rem!important;
          width: 280px;
        }
      }
    }
    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 160px;
      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }
      @media (max-width: $layout-breakpoint-medium) {
        height: 120px;
      width: 120px;
      }

      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-size: 20px;
        font-weight: 400;
        margin: 0px 0;
        font-style: italic;
        color: #3a3834;
        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }

        @media (max-width: $layout-breakpoint-medium) {
          font-size: 16px;
        }
      }
    }
  }


.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;

  @media (max-width: $layout-breakpoint-medium) {
    height: auto;
  }


  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    margin-top: -120px!important;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;
  
    @media (max-width: $layout-breakpoint-medium) {
      top: 40px;
    }
    @media (max-width: $layout-breakpoint-xsmall) {
      top: 92px;
    }
    
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.side-logo-imgs img{
  width: 77px;
  margin-right: 50px;
  margin-top: 10px;
  @media (max-width: $layout-breakpoint-medium) {
    width: 62px;
    margin-right: 34px;
    margin-top: 12px;
  }
}


.line-h-1 .row-title{
    line-height: 1.25em!important;
}

.line-h-1 .banner-row .row-title{
  
  margin-left: 20%;
  @media (max-width: $layout-breakpoint-small) {
    margin-left: 0%;
  }

}